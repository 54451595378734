@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&family=Open+Sans:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}